import React, { useEffect, useState, MouseEventHandler } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/outline';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import './DateInput.css';
import Input from '../Input';
import { useAppSelector } from 'redux/hooks';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { useDispatch } from 'react-redux';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { updateSurveySearchFilterState } from 'redux/slices/surveys';

export interface IDateInput {
	label?: string;
	required?: boolean;
	placeholder?: string;
	selected?: Date | null;
	className?: string;
	onChange?: (item: Date) => void;
	inputLabel?: string;
	iconClassName?: string;
	wrapperClassName?: 'react-datepicker-margin-y' | 'react-datepicker-margin-0';
	filterDateType?: 'incident' | 'fault' | 'survey' | 'none';
	errorMessage?: string;
	isDisabled?: boolean;
	onBlur?: () => void;
}

interface IDateInputProps {
	value: string;
	onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

const DateInput: React.FC<IDateInput> = ({
	selected,
	onChange,
	inputLabel = '',
	className,
	label,
	required = false,
	placeholder,
	iconClassName = 'text-black',
	wrapperClassName = 'react-datepicker-margin-y',
	filterDateType = 'none',
	errorMessage,
	isDisabled = false,
	onBlur,
}) => {
	const dispatch = useDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const surveyFilters = useAppSelector(
		(state) => state.surveys.searchFilter
	);
	const [date, setDate] = useState(selected);

	useEffect(() => {
		setDate(selected);
	}, [selected]);

	const handleOnChange = (newDate: Date) => {
		if (filterDateType === 'none') {
			onChange?.(newDate);
			setDate(newDate);
			return;
		}

		if (filterDateType === 'fault') {
			const filterState = JSON.parse(JSON.stringify(filters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateFilterState(filterState));
		} else if (filterDateType === 'incident') {
			const filterState = JSON.parse(JSON.stringify(incidentFilters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateIncidentFilterState(filterState));
		} else if (filterDateType === 'survey') {
			const filterState = JSON.parse(JSON.stringify(surveyFilters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateSurveySearchFilterState(filterState));
		}
	};

	const DateInput = ({ value, onClick }: IDateInputProps) => {
		return (
			<div
				onClick={isDisabled ? undefined : onClick}
				className={`${className}`}
				aria-hidden="true"
			>
				<Input
					type="text"
					value={value && format(new Date(value), 'dd MMM yyyy')}
					onChange={handleOnChange}
					iconPosition="trailing"
					Icon={CalendarIcon}
					isDisabled={isDisabled}
					className={`date-input z-40 font-inter font-normal tracking-normal m-auto`}
					size="normal"
					label={label}
					required={required}
					placeholder={placeholder}
					iconClassName={iconClassName}
					errorMessage={errorMessage}
				/>
			</div>
		);
	};

	return (
		<DatePicker
			selected={date}
			dateFormat="dd MMM yyyy"
			onChange={handleOnChange}
			onSelect={onBlur}
			customInput={<DateInput value="" onClick={() => null} />}
			className="rounded-xl"
			wrapperClassName={`${wrapperClassName}`}
		/>
	);
};

export default DateInput;
