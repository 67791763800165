import React from 'react';
import { Link } from 'react-router-dom';

export interface IStatusButton {
	className?: string;
	status:
		| 'open'
		| 'closed'
		| 'draft'
		| 'low'
		| 'medium'
		| 'high'
		| 'severe'
		| 'extreme'
		| 'completed'
		| 'submitted'
		| 'not opened'
		| 'duplicate'
		| 'nuisance';
	branch?: string;
	category?: string;
	incidentPath?: string;
	total?: number;
	isDashboardStatus?: boolean;
	extraLinkQuery?: string;
}

const StatusButton: React.FC<IStatusButton> = ({
	status,
	branch = '',
	category = '',
	incidentPath = '',
	total,
	className = '',
	isDashboardStatus = false,
	extraLinkQuery = '',
}) => {
	const textStyle = {
		open: 'text-red',
		closed: 'text-green',
		draft: 'text-blue',
		low: 'text-secondary',
		medium: 'text-secondary',
		high: 'text-secondary',
		severe: 'text-secondary',
		extreme: 'text-secondary',
		completed: 'text-green',
		submitted: 'text-blue',
		duplicate: 'text-secondary',
		nuisance: 'text-secondary',
		'not opened': 'text-red',
	};

	const borderStyle = {
		open: 'border-red',
		closed: 'border-green',
		draft: 'border-blue',
		low: 'border-secondary',
		medium: 'border-secondary',
		high: 'border-secondary',
		severe: 'border-secondary',
		extreme: 'border-secondary',
		completed: 'border-green',
		submitted: 'border-blue',
		duplicate: 'border-secondary',
		nuisance: 'border-secondary',
		'not opened': 'border-red',
	};

	var incident_link: JSX.Element | string = '';

	if (incidentPath === 'incidents') {
		if (branch) {
			incident_link = (
				<Link to={`/${incidentPath}?branch_name=${branch}&status=${status}`}>
					{status} {total && total}
				</Link>
			);
		}

		if (category) {
			incident_link = (
				<Link
					to={`/${incidentPath}?category=${category}&status=${status}&${extraLinkQuery}`}
				>
					{status} {total && total}
				</Link>
			);
		}
	} else {
		incident_link = (
			<>
				{status} {total && total}
			</>
		);
	}

	return (
		<div
			className={`flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 bg-white border ${
				isDashboardStatus
					? 'rounded-[10px] py-2 px-2 w-fit'
					: 'rounded-[5px] p-1 w-[90px]'
			} ${borderStyle[status]} ${className}`}
		>
			<p
				className={`flex-grow-0 flex-shrink-0 capitalize text-xs 2xl:text-sm w-fit text-left ${
					textStyle[status]
				} ${isDashboardStatus ? 'font-semibold' : 'font-bold'}`}
			>
				{incident_link}
			</p>
		</div>
	);
};

export default StatusButton;
