import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import Table from 'components/organisms/Table';
import Button from 'components/atoms/Button';
import { PlusIcon, SearchIcon } from '@heroicons/react/solid';
import TabLink from 'components/atoms/TabLink';
import { IQueryPeriod } from 'types/VehicleFaults';
import Search from 'components/molecules/Search';
import SlideOver from 'components/molecules/SlideOver';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	closeCommentModal,
	closeFaultModal,
	openFaultModal,
} from 'redux/slices/vehicleFaultSlice';
import CommentPanel from 'components/organisms/CommentPanel';
import VehiclePanel from 'components/organisms/VehiclePanel';
import {
	useGetVehicleFaultsMutation,
	useCreateFaultActivityMutation,
	useGetFaultActivityMutation,
	useDeleteFaultActivityMutation,
	useUpdateFaultActivityMutation,
} from 'redux/api/vehicleFaults';
import { useParams } from 'react-router-dom';
import FaultSearchBar from 'components/molecules/FaultSearchBar';
import { hasAccess } from 'utils/permissions';

const VehicleFaults = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const [filters, setFilters] = useState<string>('period=today');
	const [getFaults, { data }] = useGetVehicleFaultsMutation();
	const [open, setOpen] = useState(false);
	const faultState = useAppSelector((state) => state.vehicleFault);
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [createFaultActivity] = useCreateFaultActivityMutation();
	const [deleteFaultActivity] = useDeleteFaultActivityMutation();
	const [updateFaultActivity] = useUpdateFaultActivityMutation();
	const [getFaultActivity, { data: activityData }] =
		useGetFaultActivityMutation();
	const { uid } = useParams();

	useEffect(() => {
		if (uid) {
			setFilters(`uid=${uid}`);
		}
	}, [uid]);

	useEffect(() => {
		getFaults(filters);
	}, [filters, getFaults]);

	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const handleCloseVehicleReport = () => {
		dispatch(closeFaultModal());
	};

	const newFault = () => {
		dispatch(openFaultModal());
	};

	const isManager = hasAccess(userPermission, 'manager');

	const hasManagerBranchAccess = () => {
		return hasAccess(
			userPermission,
			'manager',
			'fault_level',
			'write',
			faultState.faultBranchId
		);
	};

	const headings = [
		'Registration',
		'Vehicle Type',
		'Faults',
		'Priority',
		'Date',
		'Creator',
		'Branch',
		'Ticket No.',
		'Status',
	];

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<MainLayout url="vehicle-faults">
			<main className="">
				<div className="flex items-center justify-center py-7 px-4 sticky top-0 z-30 bg-white">
					<Button type="secondary" onClick={handleOpen}>
						<SearchIcon className="h-5 w-5 mr-1" />
						Search
					</Button>
					<div className="flex items-center justify-center flex-1 w-full space-x-4">
						<TabLink
							onClick={() => {
								setFilters('period=today');
								setPeriod('today');
							}}
							active={period === 'today'}
						>
							Today
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=week');
								setPeriod('week');
							}}
							active={period === 'week'}
						>
							This Week
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=month');
								setPeriod('month');
							}}
							active={period === 'month'}
						>
							This Month
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=all');
								setPeriod('all');
							}}
							active={period === 'all'}
						>
							All
						</TabLink>
					</div>
					{isManager && (
						<Button onClick={newFault}>
							<PlusIcon className="h-5 w-5 mr-1" />
							New Fault
						</Button>
					)}
				</div>
				<Table
					headings={headings}
					data={data?.data}
					refetchData={() => getFaults(`period=${period}`)}
				/>
			</main>
			<Search open={open} setOpen={setOpen} searchType={'fault'}>
				<FaultSearchBar setFilters={setFilters} setOpen={setOpen} />
			</Search>
			<SlideOver open={faultState.commentsOpen} onClose={handleCloseComments}>
				<CommentPanel
					id={faultState.commentFaultId}
					onClose={handleCloseComments}
					createActivity={(payload) => createFaultActivity(payload)}
					updateActivity={updateFaultActivity}
					deleteActivity={deleteFaultActivity}
					activityData={activityData}
					getActivity={getFaultActivity}
					activityType="vehicle_fault"
					hasPermissionAccess={hasManagerBranchAccess}
				/>
			</SlideOver>
			<SlideOver
				open={faultState.vehicleFaultPanelOpen}
				onClose={handleCloseVehicleReport}
			>
				<VehiclePanel getFaults={getFaults} filters={filters} />
			</SlideOver>
		</MainLayout>
	);
};

export default VehicleFaults;
