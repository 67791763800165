import React from 'react';
import { PlusIcon, SearchIcon, UploadIcon } from '@heroicons/react/solid';
import Button from 'components/atoms/Button';
import Spinner from 'components/atoms/Spinner';
import TabLink from 'components/atoms/TabLink';
import IncidentSearchBar from 'components/molecules/IncidentSearchBar';
import Search from 'components/molecules/Search';
import SlideOver from 'components/molecules/SlideOver';
import CommentPanel from 'components/organisms/CommentPanel';
import IncidentPanel from 'components/organisms/IncidentPanel';
import Table from 'components/organisms/Table';
import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	useCreateIncidentActivityMutation,
	useCreateIncidentMutation,
	useDeleteIncidentActivityMutation,
	useGetIncidentActivityMutation,
	useGetIncidentMutation,
	useGetVehicleIncidentsMutation,
	useUpdateIncidentActivityMutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector, useQuery } from 'redux/hooks';
import {
	closeCommentModal,
	closeIncidentModal,
	openIncidentModal,
	updateIncidentActionState,
} from 'redux/slices/vehicleIncidentsSlice';
import { IQueryPeriod } from 'types/VehicleIncidents';
import { hasAccess } from 'utils/permissions';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

const Incidents = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const [filters, setFilters] = useState<string>('period=today');
	const [open, setOpen] = useState(false);
	const [getIncidents, { data, isSuccess }] = useGetVehicleIncidentsMutation();
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const userId = useAppSelector((state) => state.user.id);
	const [createIncidentActivity] = useCreateIncidentActivityMutation();
	const [deleteIncidentActivity] = useDeleteIncidentActivityMutation();
	const [updateIncidentActivity] = useUpdateIncidentActivityMutation();
	const [createIncident, { isLoading }] = useCreateIncidentMutation();
	const [getIncident] = useGetIncidentMutation();
	const [getIncidentActivity, { data: activityData }] =
		useGetIncidentActivityMutation();
	const { uid, action } = useParams();
	const query = useQuery();
	const navigate = useNavigate();
	const [previousReportOpen, setPreviousReportOpen] = useState<boolean>(false);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (uid) {
			if (action?.toLowerCase() === 'view') {
				dispatch(updateIncidentActionState('view'));
			}
			setFilters(`uid=${uid}`);
		}
		// eslint-disable-next-line
	}, [uid]);

	useEffect(() => {
		if (query && query.get('view') && !previousReportOpen) {
			dispatch(updateIncidentActionState('view'));
			setFilters(`id=${query.get('view')}`);
		}
		// eslint-disable-next-line
	}, [query]);

	useEffect(() => {
		if (searchParams.get('branch_name') || searchParams.get('category')) {
			const filterObj = {};

			if (searchParams.get('branch_name')) {
				//@ts-ignore
				filterObj.branch = searchParams.get('branch_name');
			}

			if (searchParams.get('status')) {
				//@ts-ignore
				filterObj.status = searchParams.get('status');
			}

			if (searchParams.get('category')) {
				//@ts-ignore
				filterObj.incidentCategory = searchParams.get('category');
			}

			if (searchParams.get('dateFrom')) {
				//@ts-ignore
				filterObj.from = searchParams.get('dateFrom');
			}

			if (searchParams.get('dateTo')) {
				//@ts-ignore
				filterObj.to = searchParams.get('dateTo');
			}

			setFilters(new URLSearchParams(filterObj).toString());
			setPeriod('all');
		}
		console.log(filters);
		getIncidents(filters);
	}, [searchParams, filters, getIncidents, IncidentState.incidentSection1Id]);

	useEffect(() => {
		if (isSuccess && data && IncidentState.action) {
			if (IncidentState.action === 'view' && data.data.length) {
				const selectedIncident = data.data[0];
				const payload = {
					incidentReportId: selectedIncident.id,
					incidentBranchId: selectedIncident.branch.id,
					incidentSection1Id: selectedIncident?.incident_section_1?.id,
					incidentSection2Id: selectedIncident?.incident_section_2?.id,
					incidentSection3Id: selectedIncident?.incident_section_3?.id,
					incidentSection4Id: selectedIncident?.incident_section_4?.id,
					incidentSection5Id: selectedIncident?.incident_section_5?.id,
				};
				dispatch(openIncidentModal(payload));
				createIncidentActivity({
					incident: selectedIncident.id,
					user: userId,
					type: 'view report',
					content: 'Report opened',
				});
				dispatch(updateIncidentActionState(null));
			}
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);
	useEffect(() => {
		if (
			!IncidentState.incidentReportOpen &&
			previousReportOpen &&
			query.get('view')
		) {
			navigate('/incidents');
		} else if (
			IncidentState.incidentReportOpen &&
			!previousReportOpen &&
			!query.get('view') &&
			IncidentState.incidentReportId
		) {
			navigate(`/incidents?view=${IncidentState.incidentReportId}`);
		}
		setPreviousReportOpen(IncidentState.incidentReportOpen);
		// eslint-disable-next-line
	}, [IncidentState.incidentReportOpen]);

	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const handleCloseIncidentReport = () => {
		dispatch(closeIncidentModal());
		getIncidents(filters);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const createNewIncident = async () => {
		//find branch to attach to incident as a default
		const branchIdMap = userPermission?.map((permission) =>
			Number(permission.branch.id)
		);

		//create a new incident
		if (branchIdMap) {
			const newIncident = await createIncident({
				branch: branchIdMap[0],
			});

			const newIncidentId = 'data' in newIncident ? newIncident.data?.id : null;

			if (!newIncidentId) {
				return;
			}

			await getIncident(newIncidentId)
				.unwrap()
				.then((response) => {
					const {
						incident_section_1,
						incident_section_2,
						incident_section_3,
						incident_section_4,
						incident_section_5,
					} = response?.incidentData;

					const updateIncidentStatePayload = {
						incidentReportId: newIncidentId,
						incidentBranchId: branchIdMap[0].toString(),
						incidentSection1Id: incident_section_1.id,
						incidentSection2Id: incident_section_2.id,
						incidentSection3Id: incident_section_3.id,
						incidentSection4Id: incident_section_4.id,
						incidentSection5Id: incident_section_5.id,
						isNewIncident: true,
					};
					dispatch(openIncidentModal(updateIncidentStatePayload));
				});
		}
	};

	const hasManagerAccess = () => {
		return hasAccess(userPermission, 'manager', 'incident_level', 'write');
	};

	const headings = [
		'Branch',
		'Incident #',
		'Category',
		'Date',
		'Person Involved',
		'Severity',
		'Ticket No.',
		'Client',
		'Created By',
		'Status',
	];

	if (isLoading) {
		return (
			<MainLayout url="incidents">
				<Spinner />
			</MainLayout>
		);
	}

	const extractText: string | number | any = (
		children: string | number | any
	) => {
		if (typeof children === 'string' || typeof children === 'number') {
			return children;
		}

		if (Array.isArray(children)) {
			return children.map((child) => extractText(child)).join(' ');
		}

		//@ts-ignore
		if (React.isValidElement(children) && children.props.children) {
			//@ts-ignore
			return extractText(children.props.children);
		}

		return '';
	};

	const renderHeadings = () => {
		if (!data?.data) return [];
		const headings = [
			{
				value: 'Branch',
				alignment: 'left',
			},
			{
				value: 'Incident #',
				alignment: 'left',
			},
			{
				value: 'Category',
				alignment: 'left',
			},
			{
				value: 'Date',
				alignment: 'left',
			},
			{
				value: 'Person Involved',
				alignment: 'left',
			},
			{
				value: 'Severity',
				alignment: 'left',
			},
			{
				value: 'Ticket No.',
				alignment: 'left',
			},
			{
				value: 'Client',
				alignment: 'left',
			},
			{
				value: 'Created By',
				alignment: 'left',
			},
			{
				value: 'Status',
				alignment: 'left',
			},
		];

		return headings;
	};

	const renderRows = () => {
		if (!data?.data) return [];
		const rows = data.data.map((row) => {
			return {
				type: 'default',
				values: [
					{
						value: row.branch.name,
						alignment: 'left',
					},
					{
						value: row.id,
						alignment: 'left',
					},
					{
						value:
							row.incident_section_4?.incident_category &&
							row.incident_section_4?.incident_category.length
								? `${row.incident_section_4.incident_category[0].label}`
								: `No Category`,
						alignment: 'left',
					},
					{
						value: new Date(row.incident_section_1?.incident_date)
							.toLocaleDateString('en-AU', {
								weekday: 'short',
								day: 'numeric',
								month: 'short',
							})
							.replace(',', ''),
						alignment: 'left',
					},
					{
						value:
							row.incident_section_2?.personInvolved &&
							row.incident_section_2.personInvolved.length
								? `${row.incident_section_2.personInvolved[0].personInvolved_name}`
								: '',
						alignment: 'left',
					},
					{
						value: row.incident_section_4?.severity_rating,
						alignment: 'left',
					},
					{
						value: row.ticket_number,
						alignment: 'left',
					},
					{
						value: row.client,
						alignment: 'left',
					},
					{
						value: row.submitted_by,
						alignment: 'left',
					},
					{
						value: row.status,
						alignment: 'left',
					},
				],
			};
		});
		return rows || [];
	};

	const countTotalIncidentsPerCategory = () => {
		const categoryCountMap: { [key: string]: number } = {};

		data?.data?.forEach(
			(incident: { incident_section_4: { incident_category: any[] } }) => {
				incident.incident_section_4?.incident_category?.forEach(
					(category: { label: string | number }) => {
						categoryCountMap[category.label] =
							(categoryCountMap[category.label] || 0) + 1;
					}
				);
			}
		);

		return categoryCountMap;
	};

	const countIncidentsByBranchAndCategory = () => {
		const branchCategoryCountMap: { [key: string]: { [key: string]: number } } =
			{};

		data?.data?.forEach(
			(incident: {
				branch: { name: any };
				incident_section_4: { incident_category: any[] };
			}) => {
				const branchName = incident.branch.name;
				incident.incident_section_4?.incident_category?.forEach((category) => {
					if (!branchCategoryCountMap[branchName]) {
						branchCategoryCountMap[branchName] = {};
					}
					branchCategoryCountMap[branchName][category.label] =
						(branchCategoryCountMap[branchName][category.label] || 0) + 1;
				});
			}
		);

		return branchCategoryCountMap;
	};

	const totalIncidentsPerCategory = countTotalIncidentsPerCategory();
	const incidentsByBranchAndCategory = countIncidentsByBranchAndCategory();

	const exportToExcel = () => {
		const workbook = XLSX.utils.book_new();

		const sheetData = [['Category Report'], ['Category', 'Total Incidents']];
		for (const category in totalIncidentsPerCategory) {
			if (totalIncidentsPerCategory.hasOwnProperty(category)) {
				const totalAsString = String(totalIncidentsPerCategory[category]);
				sheetData.push([category, totalAsString]);
			}
		}
		const sheet = XLSX.utils.aoa_to_sheet(sheetData);

		XLSX.utils.book_append_sheet(workbook, sheet, 'Category Report');

		for (const branch in incidentsByBranchAndCategory) {
			if (incidentsByBranchAndCategory.hasOwnProperty(branch)) {
				const branchData = incidentsByBranchAndCategory[branch];
				const sheetData = [
					[`${branch} Incidents`],
					['Category', 'Branch Total', 'Total', 'Percentage'],
				];

				for (const category in branchData) {
					if (branchData.hasOwnProperty(category)) {
						const incidents = branchData[category];
						const categoryTotal = totalIncidentsPerCategory[category] || 0;
						const percentage =
							((incidents / categoryTotal) * 100).toFixed(2) + '%';
						sheetData.push([
							category,
							String(incidents),
							String(categoryTotal),
							percentage,
						]);
					}
				}

				const sheet = XLSX.utils.aoa_to_sheet(sheetData);
				XLSX.utils.book_append_sheet(workbook, sheet, branch);
			}
		}

		XLSX.writeFile(workbook, 'report.xlsx');
	};

	return (
		<MainLayout
			url="incidents"
			query={query.get('view') ? `?view=${query.get('view')}` : undefined}
		>
			<main className="">
				<div className="flex items-center justify-center py-7 px-4 sticky top-0 z-30 bg-white">
					<Button type="secondary" onClick={handleOpen}>
						<SearchIcon className="h-5 w-5 mr-1" />
						Search
					</Button>
					<div className="flex items-center justify-center flex-1 w-full space-x-4">
						<TabLink
							onClick={() => {
								setFilters('period=today');
								setPeriod('today');
							}}
							active={period === 'today'}
						>
							Today
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=week');
								setPeriod('week');
							}}
							active={period === 'week'}
						>
							This Week
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=month');
								setPeriod('month');
							}}
							active={period === 'month'}
						>
							This Month
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=all');
								setPeriod('all');
							}}
							active={period === 'all'}
						>
							All
						</TabLink>
					</div>
					{filters.includes('incidentCategory') ? (
						<Button onClick={exportToExcel} className="mr-4">
							<UploadIcon className="h-5 w-5 mr-1" />
							Export
						</Button>
					) : (
						<CSVLink
							data={[...renderRows()].map((row) =>
								row.values.map((value) => extractText(value.value))
							)}
							headers={[
								...renderHeadings().map((heading) =>
									extractText(heading.value)
								),
							]}
							filename="report"
							className="px-3 mr-4 py-2 text-sm rounded-[5px] relative flex items-center justify-center p-4 text-black border bg-primary border-primary"
						>
							<UploadIcon className="h-5 w-5 mr-1" />
							Export
						</CSVLink>
					)}
					{hasManagerAccess() && (
						<Button onClick={createNewIncident}>
							<PlusIcon className="h-5 w-5 mr-1" />
							New Report
						</Button>
					)}
				</div>
				<Table
					headings={headings}
					data={data?.data}
					refetchData={() => getIncidents(filters)}
				/>
			</main>
			<Search open={open} setOpen={setOpen} searchType={'incident'}>
				<IncidentSearchBar
					setFilters={setFilters}
					setOpen={setOpen}
					setPeriod={setPeriod}
				/>
			</Search>
			<SlideOver
				open={IncidentState.commentsOpen}
				onClose={handleCloseComments}
			>
				<CommentPanel
					id={IncidentState.commentIncidentId}
					onClose={handleCloseComments}
					createActivity={(payload) => createIncidentActivity(payload)}
					updateActivity={updateIncidentActivity}
					deleteActivity={deleteIncidentActivity}
					activityData={activityData}
					getActivity={getIncidentActivity}
					activityType="incident"
					hasPermissionAccess={hasManagerAccess}
				/>
			</SlideOver>
			<SlideOver
				open={IncidentState.incidentReportOpen}
				onClose={handleCloseIncidentReport}
			>
				<IncidentPanel
					getIncidents={getIncidents}
					filters={filters}
					onClose={handleCloseIncidentReport}
				/>
			</SlideOver>
		</MainLayout>
	);
};

export default Incidents;
