import {
	IVehicleFault,
	ISortFaultData,
	IVehicleFaultQuestion,
	IVehicleFaultTypes,
	IVehicleCreateFaultPayload,
} from 'types/VehicleFaults';
import { baseAPI } from './baseAPI';
import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';

interface IAssignPayload {
	id: number;
	body: {
		status: 'open' | 'closed';
		assignees: IMultiSelectComboBoxOption[];
		ticket_number: string;
	};
}

export interface IVehicleCommentPayload {
	vehicle_fault?: number;
	type: 'comment';
	content: string;
	user: number;
}

export interface IVehicleBranch {
	id: number;
	name: string;
	user_permissions: IMultiSelectComboBoxOption[];
}

export interface IVehicleFaultActivity {
	content: string;
	createdAt: Date;
	id: number;
	type: 'comment' | 'status' | 'assign';
	user?: {
		id: string;
		first_name: string;
		last_name: string;
		avatar?: {
			url: string;
		};
	};
}

export const inventoryAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		getVehicleFaults: builder.mutation<
			{ data: IVehicleFault[]; sortData: ISortFaultData[] },
			string
		>({
			query(query) {
				return {
					url: `/vehicle-faults?${query}`,
					method: 'GET',
				};
			},
		}),
		getVehicleQuestions: builder.query<
			{ data: IVehicleFaultQuestion[]; dropdownData: IVehicleFaultTypes[] },
			void
		>({
			query() {
				return {
					url: '/vehicle-fault-questions',
					method: 'GET',
				};
			},
		}),
		getBranch: builder.mutation<IVehicleBranch, number>({
			query(id) {
				return {
					url: `/branches/${id}`,
					method: 'GET',
				};
			},
		}),
		createVehicleFault: builder.mutation<void, IVehicleCreateFaultPayload>({
			query(payload) {
				return {
					url: `/vehicle-faults`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		assignFault: builder.mutation<void, IAssignPayload>({
			query(payload) {
				return {
					url: `/vehicle-faults/${payload.id}`,
					method: 'PUT',
					body: payload.body,
				};
			},
		}),
		getFaultActivity: builder.mutation<IVehicleFaultActivity[], number>({
			query(faultId) {
				return {
					url: `/vehicle-faults/${faultId}/activity`,
					method: 'GET',
				};
			},
		}),
		createFaultActivity: builder.mutation<void, IVehicleCommentPayload>({
			query(payload) {
				return {
					url: `/vehicle-fault-activities/`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		deleteFaultActivity: builder.mutation<void, number>({
			query(activityId) {
				return {
					url: `/vehicle-fault-activities/${activityId}`,
					method: 'DELETE',
				};
			},
		}),
		updateFaultActivity: builder.mutation<
			void,
			{ activityId: number; content: string }
		>({
			query(payload) {
				return {
					url: `/vehicle-fault-activities/${payload.activityId}`,
					method: 'PUT',
					body: payload,
				};
			},
		}),
	}),
});

export const {
	useGetVehicleFaultsMutation,
	useGetBranchMutation,
	useGetVehicleQuestionsQuery,
	useAssignFaultMutation,
	useCreateVehicleFaultMutation,
	useCreateFaultActivityMutation,
	useGetFaultActivityMutation,
	useDeleteFaultActivityMutation,
	useUpdateFaultActivityMutation,
} = inventoryAPI;
